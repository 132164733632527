import React from "react";

class BlogPostLayout extends React.Component<any, any> {

	constructor(props) {
		super(props);
	}

	public render() {

		const style = {
			gridColumnEnd: "rightside",
			gridColumnStart: "leftside",
		};

		return(
			<div className="blogPostLayout" style={style}>{this.props.children}</div>
		);
	}
}

export default BlogPostLayout;
