import { graphql } from "gatsby";
import React from "react";
import BlogPostLayout from "../components/blog/BlogPostLayout";
import Post from "../components/blog/PostLink";
import Header from "../components/header";
import Layout from "../components/layout/layout";

export const pageQuery = graphql`
	query GetPublishedPosts {
		allMdx(
			sort: {fields: frontmatter___date, order: DESC}
			filter: {frontmatter: {published: {eq: true}}}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						date(formatString: "MMMM Do, YYYY")
						path
						tags
					}
					slug
				}
			}
		}
	}
`;

class Blog extends React.Component<any, any> {

	constructor(props) {
		super(props);

		//console.log(this.props.data)

		this.state = {
			data: this.props.data.allMdx.edges,
		};
	}

	public render() {

		// You can filter your posts based on some criteria
		const Posts = this.props.data.allMdx.edges.filter( (edge) => !!edge.node.frontmatter.date ).map((edge) => <Post key={edge.node.id} post={edge.node} /> );

		return(
			<React.Fragment>
				<Header />
				<Layout>
					<div className="header">
						<h1>Blog</h1>
					</div>
					<BlogPostLayout>{ Posts }</BlogPostLayout>
				</Layout>
			</React.Fragment>
		);

	}
}

export default Blog;
